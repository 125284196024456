<template>
    <div class="section">
        <page-title :title="$t('client.pages.myAccount.title')" class="my-3"></page-title>
        <b-card :header="$t('client.pages.myAccount.form.resetPassword.title')" class="text-center" header-tag="h4">
            <b-row class="my-3">
                <b-col lg="4"></b-col>
                <b-col lg="4" xxl="4" xl="4">
                    <b-card-text>
                        <change-password></change-password>
                    </b-card-text>
                </b-col>
                <b-col lg="4"></b-col>
            </b-row>
        </b-card>
        <b-row class="my-4">
            <b-col lg="12" xxl="12">
                <page-title :title="$t('sst.components.documentCard.title')" class="my-3"></page-title>
            </b-col>
        </b-row>
        <b-card>
            <spinner v-if="refreshData"></spinner>
            <b-row class="my-3" v-if="!refreshData">
                <b-col lg="4" xxl="6" v-for="(document, key) in data" :key="key">
                    <sst-document-card :documentKey="key" :document="document" @uploadDocument="uploadDocument"></sst-document-card>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import ChangePassword from '@/components/auth/ChangePassword';
import SstDocumentCard from '@/components/sst/profile/SstDocumentCard';
import Spinner from '@/components/shared/element/Spinner';
import { SstRepository } from '@/repositories';

export default {
    name: 'SstMyAccount',
    components: {
        PageTitle,
        ChangePassword,
        SstDocumentCard,
        Spinner
    },
    data() {
        return {
            data: [],
            refreshData: true
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.refreshData = true;
            SstRepository.accountData()
                .then(response => {
                    this.refreshData = false;
                    this.data = response.data.data;
                    if (this.data && this.data.iban_document) {
                        delete this.data.iban_document;
                    }
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        },
        uploadDocument() {
            this.getData();
        }
    }
};
</script>
