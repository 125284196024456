<template>
    <b-card :header="getNameByKey()" class="text-center min-height-120" header-tag="h4">
        <b-card-text class="min-height-120">

            <input type="file" @change="uploadFile" class="d-none" :ref="'file_' + documentKey" />

            <image-uploader
                :maxWidth="fileConstants.MAX_WIDTH"
                :quality="fileConstants.QUALITY"
                :autoRotate="true"
                outputFormat="verbose"
                :preview="false"
                :capture="false"
                doNotResize="['gif', 'svg', 'pdf']"
                @change="uploadFile"
                class="d-none"
                :ref="'file_' + documentKey"
                :id="'fileInput_'+ documentKey"
            ></image-uploader>

            <div v-if="document">
                <div class="ft-2rem" v-if="document.validation.validated === true || document.validation === null">
                    <a :href="document.downloadUrl" target="_blank" class="cursor-pointer">
                        <b-icon icon="cloud-download" class="border border-info rounded p-2 mr-2" variant="info"></b-icon>
                    </a>
                    <a @click="openFileUpload()" class="cursor-pointer">
                        <b-icon icon="cloud-upload" class="border border-info rounded p-2" variant="info"></b-icon>
                    </a>
                </div>

                <div v-if="document.validation.cancellation_reason !== null">
                    <div class="ft-2rem">
                        <a :href="document.downloadUrl" target="_blank" class="cursor-pointer">
                            <b-icon icon="cloud-download" class="border border-info rounded p-2 mr-2" variant="info"></b-icon>
                        </a>
                        <a @click="openFileUpload()" class="cursor-pointer">
                            <b-icon icon="cloud-upload" class="border border-info rounded p-2" variant="info"></b-icon>
                        </a>
                    </div>
                    <span v-if="document.validation.cancellation_memo !== null">
                        {{
                            $t('sst.components.documentCard.labels.documentRefusedWithMemo', {
                                cancelReason: document.validation.cancellation_reason_trad,
                                cancelMemo: document.validation.cancellation_memo
                            })
                        }}
                    </span>
                    <span v-else>
                        {{
                            $t('sst.components.documentCard.labels.documentRefusedWithoutMemo', {
                                cancelReason: document.validation.cancellation_reason_trad
                            })
                        }}
                    </span>
                </div>
                <div v-if="document.validation.validated === false && document.validation.cancellation_reason === null">
                    {{ $t('sst.components.documentCard.labels.documentNotValidated') }}
                </div>
                <div v-if="document.validation.validated === true">
                    {{ $t('sst.components.documentCard.labels.documentValidated') }}
                </div>
            </div>
            <div v-else>
                <span>
                    <div class="ft-2rem">
                        <a @click="openFileUpload()" class="cursor-pointer">
                            <b-icon icon="cloud-upload" class="border border-info rounded p-2" variant="info"></b-icon>
                        </a>
                    </div>
                    {{ $t('sst.components.documentCard.labels.documentNotExist') }}
                </span>
            </div>
        </b-card-text>
    </b-card>
</template>

<script>
import { SstRepository } from '@/repositories';
import InterventionHelper from '@/utils/interventionHelper';
import FileHelper from '@/utils/fileHelper';
import { FILE } from '@/constants';

export default {
    name: 'SstDocumentCard',
    props: {
        documentKey: {
            type: String,
            required: true
        },
        document: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            fileConstants: FILE
        };
    },
    methods: {
        getNameByKey() {
            return this.$t('sst.components.documentCard.names.' + this.documentKey);
        },
        openFileUpload() {
            let fileInputElement = document.getElementById('fileInput_'+ this.documentKey);
            fileInputElement.accept = 'image/*,application/pdf';
            fileInputElement.click();
        },
        uploadFile(event) {
            const formData = new FormData();
            let file = event instanceof File ? event : FileHelper.dataURLtoFile(event.dataUrl, event.info.name);
            formData.append('document_type', InterventionHelper.getDocumentIdByKey(this.documentKey));
            formData.append('filename', file);

            SstRepository.uploadAccountDocument(formData)
                .then(() => {
                    this.$toast.success(this.$i18n.t('sst.pages.oneIntervention.uploadDropdown.notifications.success'));
                    this.$emit('uploadDocument');
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.error(this.$i18n.t('sst.pages.oneIntervention.uploadDropdown.notifications.error'));
                });

        }
    }
};
</script>

<style scoped>
.min-height-120 {
    min-height: 120px;
}
.ft-2rem {
    font-size: 2.5rem;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
