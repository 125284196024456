import { INTERVENTION } from '@/constants';

export default class InterventionHelper {
    static getDocumentIdByKey(key) {
        switch (key) {
            case INTERVENTION.UPLOAD_DOCUMENT_KEYS.SST_IBAN_KEY:
                return INTERVENTION.UPLOAD_DOCUMENT_TYPES.SST_IBAN;
            case INTERVENTION.UPLOAD_DOCUMENT_KEYS.SST_KBIS_KEY:
                return INTERVENTION.UPLOAD_DOCUMENT_TYPES.SST_KBIS;
            case INTERVENTION.UPLOAD_DOCUMENT_KEYS.SST_URSSAFF_KEY:
                return INTERVENTION.UPLOAD_DOCUMENT_TYPES.SST_URSSAFF;
            case INTERVENTION.UPLOAD_DOCUMENT_KEYS.SST_INSURANCE_KEY:
                return INTERVENTION.UPLOAD_DOCUMENT_TYPES.SST_INSURANCE;
        }
    }
}
